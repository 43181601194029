import React, { Fragment, useState, useEffect } from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import { isBrowser } from "../../../../context/ApolloContext"

import {
  BottomStickyBanner,
  StickyContainer,
  StickyText,
  StickyButton,
  PathologiesWrapper,
  PathologiesRow,
} from "../../../../components/BackPainPageLayout/styled"

import Footer from "../../../../components/Layout/Footer"
import SeoComp from "../../../../components/SeoComp"
import PathologiesTopSection from "../../../../components/BackPainPageLayout/PathologiesTopSection"
import PathologiesMiddleSection from "../../../../components/BackPainPageLayout/PathologiesMiddleSection"
import PathologiesBottomSection from "../../../../components/BackPainPageLayout/PathologiesBottomSection"

const $ = isBrowser ? require("jquery") : {}

const pageClassName = "LaCruralgie"

const LaCruralgie = ({ intl, data }) => {
  const scrollContent = () => {
    $("." + pageClassName + " li").click(function() {
      $("html, body").animate(
        {
          scrollTop:
            $("#" + pageClassName + "_" + $(this).index()).offset().top - 200,
        },
        1500
      )
    })
  }

  useEffect(() => {
    scrollContent()
  }, [])
  return (
    data &&
    data.allDirectusVosMauxSubPageTranslation.nodes.length > 0 && (
      <Fragment>
        <SeoComp
          description={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].meta_description
          }
          title={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].meta_title
          }
          twitter_card={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].twitter_card
          }
          image={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].image &&
            data.allDirectusSeoTranslation.nodes[0].image.data &&
            data.allDirectusSeoTranslation.nodes[0].image.data.url
          }
          hreflangPathname={{
            fr: `les-maux-de-dos/les-pathologies/la-cruralgie`,
            en: "",
            de: "",
            es: "",
          }}
        />

        <PathologiesWrapper>
          {data.allDirectusVosMauxSubPageTranslation.nodes[0]
            .first_desktop_image &&
            data.allDirectusVosMauxSubPageTranslation.nodes[0]
              .first_section_text && (
              <PathologiesRow padding="0px">
                <PathologiesTopSection
                  image={
                    data.allDirectusVosMauxSubPageTranslation.nodes[0]
                      .first_desktop_image
                  }
                  text={
                    data.allDirectusVosMauxSubPageTranslation.nodes[0]
                      .first_section_text
                  }
                  pageID={pageClassName}
                />
              </PathologiesRow>
            )}
          {data.allDirectusVosMauxSubPageTranslation.nodes[0]
            .second_section_text && (
            <PathologiesRow padding="0px">
              <PathologiesMiddleSection
                image={
                  data.allDirectusVosMauxSubPageTranslation.nodes[0]
                    .second_image
                }
                text={
                  data.allDirectusVosMauxSubPageTranslation.nodes[0]
                    .second_section_text
                }
                pageID={`${pageClassName}_0`}
              />
            </PathologiesRow>
          )}
          {data.allDirectusVosMauxSubPageTranslation.nodes[0]
            .third_section_text && (
            <PathologiesBottomSection
              bgColor="gradient"
              text={
                data.allDirectusVosMauxSubPageTranslation.nodes[0]
                  .third_section_text
              }
              pageID={`${pageClassName}_1`}
            />
          )}
          {data.allDirectusVosMauxSubPageTranslation.nodes[0]
            .fourth_section_text && (
            <PathologiesBottomSection
              text={
                data.allDirectusVosMauxSubPageTranslation.nodes[0]
                  .fourth_section_text
              }
              pageID={`${pageClassName}_2`}
            />
          )}
          {data.allDirectusVosMauxSubPageTranslation.nodes[0]
            .fifth_section_text && (
            <PathologiesBottomSection
              bgColor="green"
              text={
                data.allDirectusVosMauxSubPageTranslation.nodes[0]
                  .fifth_section_text
              }
              pageID={`${pageClassName}_3`}
            />
          )}
          {/* {data.allDirectusVosMauxSubPageTranslation.nodes[0]
            .sticky_banner_text && (
            <BottomStickyBanner>
              <StickyContainer>
                <StickyText
                  dangerouslySetInnerHTML={{
                    __html:
                      data.allDirectusVosMauxSubPageTranslation.nodes[0]
                        .sticky_banner_text,
                  }}
                />
                <StickyButton
                  to={
                    data.allDirectusVosMauxSubPageTranslation.nodes[0]
                      .sticky_button_link
                  }
                >
                  {
                    data.allDirectusVosMauxSubPageTranslation.nodes[0]
                      .sticky_button_text
                  }
                </StickyButton>
              </StickyContainer>
            </BottomStickyBanner>
          )} */}
        </PathologiesWrapper>

        {data.allDirectusFooterTranslation.nodes.length > 0 && (
          <Footer data={data.allDirectusFooterTranslation.nodes[0]} />
        )}
      </Fragment>
    )
  )
}

export const query = graphql`
  query($lang: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allDirectusVosMauxSubPageTranslation(
      filter: {
        language: { eq: $lang }
        vos_maux_sub_page: { name: { eq: "La Cruralgie" } }
      }
    ) {
      nodes {
        page_title
        page_image {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 550, quality: 100)
            }
            extension
          }
        }
        introduction_text
        page_content {
          list {
            title
            text
            text_list {
              title
              text
            }
          }
        }
        sub_page_bottom_section_title
        page_content_with_image {
          list {
            image
            title
            text
            text_list {
              title
              text
            }
          }
        }
        sticky_banner_text
        sticky_button_text
        sticky_button_link
        first_section_text
        second_section_text
        third_section_text
        fourth_section_text
        fifth_section_text
        first_desktop_image {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 850, quality: 100)
            }
            extension
          }
        }
        first_mobile_image {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 850, quality: 100)
            }
            extension
          }
        }
        second_image {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 850, quality: 100)
            }
            extension
          }
        }
      }
    }
    allDirectusFooterTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        facebook
        gbp_facebook
        youtube
        twitter
        instagram
        linkedin
        about_us {
          name
          list {
            link
            list
          }
        }
        contact {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        purchases {
          list {
            list
            link
          }
          name
        }
        help {
          list {
            link
            list
          }
          name
        }
        about_us_menu {
          name
          list {
            link
            list
          }
        }
        knowledge_center_menu {
          name
          list {
            link
            list
          }
        }
        shop_menu {
          name
          list {
            link
            list
          }
        }
        support_menu {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        newsletter_heading
        follow_us_heading
        footer_bottom {
          list {
            link
            list
          }
        }
      }
    }
    allDirectusSeoTranslation(
      filter: {
        language: { eq: $lang }
        seo: { page_name: { eq: "la_cruralgie" } }
      }
    ) {
      nodes {
        meta_title
        meta_description
        twitter_card
        twitter_title
        url
        image {
          data {
            url
          }
        }
      }
    }
  }
`

export default injectIntl(LaCruralgie)
